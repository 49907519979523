<template>
  <v-navigation-drawer
    v-if="$vuetify.breakpoint.mobile"
    id="AppMenuDrawer"
    v-model="drawer"
    :mini-variant="mini"
    :permanent="false"
    :temporary="true"
    absolute
    width="280"
    mini-variant-width="60"
    app
  >
    <template v-slot:prepend>
      <app-menu-header :mini="mini" />
    </template>

    <v-row>
      <v-col>
        <app-nav-menu :mini="mini" />
      </v-col>
    </v-row>

    <template v-slot:append>
      <app-menu-footer v-model="mini" />
    </template>
  </v-navigation-drawer>
  <v-navigation-drawer
    v-else
    id="AppMenuDrawer"
    :mini-variant="mini"
    :permanent="true"
    :temporary="false"
    width="280"
    mini-variant-width="60"
    app
  >
    <template v-slot:prepend>
      <app-menu-header :mini="mini" />
    </template>

    <v-row>
      <v-col>
        <app-nav-menu :mini="mini" />
      </v-col>
    </v-row>

    <template v-slot:append>
      <app-menu-footer v-model="mini" />
    </template>
  </v-navigation-drawer>
</template>

<script>
  export default {
    name: 'AppMenuDrawer',
    components: {
      AppNavMenu: () => import('./AppNavMenu'),
      AppMenuHeader: () => import('./AppMenuHeader'),
      AppMenuFooter: () => import('./AppMenuFooter'),
    },

    data: () => ({}),
    computed: {
      drawer: {
        get () {
          return this.$store.getters.drawer
        },
        set (val) {
          this.$store.dispatch('app/changeDrawer', val)
        },
      },
      mini: {
        get () {
          return this.$store.getters.menuMini
        },
        set (val) {
          this.$store.dispatch('app/changeMenuMini', val)
        },
      },
    },
    mounted () {},
    methods: {},
  }
</script>

<style lang="scss" >
@import "@/styles/vuetify-preset-plus/light_theme/_variables.sass";

//scrollbar
#AppMenuDrawer .v-navigation-drawer__content {
  &::-webkit-scrollbar {
    width: 4px;
    background: $neutral-250;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: $neutral-400;
    border-radius: 2px;
    width: 4px;
  }
}
</style>
